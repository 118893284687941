import "swiper/css";
import "swiper/css/effect-fade";

import { EffectFade, Navigation, Thumbs } from "swiper/modules";

// https://swiperjs.com/demos
import Swiper from "swiper";

const swiper = new Swiper(".swiper", {
	modules: [
		Navigation,
	],
	slidesPerView: 1.3,
	spaceBetween: 24,
	grabCursor: true,
	speed: 800,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	breakpoints: {
		480: {
			slidesPerView: 1.5,
		},
		640: {
			slidesPerView: 2.25,
		},
		768: {
			slidesPerView: 2.5,
		},
		960: {
			slidesPerView: 3,
		},
		1280: {
			slidesPerView: 3,
			spaceBetween: 40,
		},
	},
});

var productThumbnails = new Swiper(".productThumbnailsSwiper", {
	slidesPerView: 3,
	slidesPerGroup: 1,
	freeMode: true,
	watchSlidesProgress: true,
	spaceBetween: 10,
	slideToClickedSlide: true,
	on: {
		click: function (a,b) {
			const nextSlide = this.slides[this.clickedIndex+1];
			if (nextSlide) {
				if (!nextSlide.classList.contains('swiper-slide-visible')) {
					this.slideTo(this.clickedIndex);
					return;
				}
			}

			const prevSlide = this.slides[this.clickedIndex-1];
			// console.log(prevSlide);
			if (prevSlide) {
				if (!prevSlide.classList.contains('swiper-slide-visible')) {
					// console.log('slide to ', this.clickedIndex-1);
					this.slideTo(this.clickedIndex-1);
				}
			}
		},
	},
});


window.productImagesSlider = new Swiper(".productImagesSwiper", {
	modules: [
		Thumbs,
		Navigation,
],
	thumbs: {
		swiper: productThumbnails.slides.length ? productThumbnails : null,
	},
	navigation: {
		prevEl: ".productImagesSwiper ~ .swiper-button-prev",
		nextEl: ".productImagesSwiper ~ .swiper-button-next",
	},
});

window.largeProductImages = new Swiper(".largeImagesSwiper", {
	modules: [
		Navigation,
],
	navigation: {
		nextEl: ".largeImagesSwiper .swiper-button-next",
		prevEl: ".largeImagesSwiper .swiper-button-prev",
	},
});

new Swiper(".comparisonSwiper", {
	modules: [Navigation, EffectFade],
	grabCursor: true,
	effect: "fade",
	fadeEffect: {
		crossFade: true,
	},
	resistanceRatio: 0,
	navigation: {
		nextEl: ".swiper-button-after",
		prevEl: ".swiper-button-before",
	},
});

new Swiper(".openingHourSwiper", {
	modules: [
		Navigation,
	],
	slidesPerView: 1,
	spaceBetween: 24,
	grabCursor: true,
	autoHeight: true,
	speed: 800,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	on: {
		init: function () {
			const activeSlide = this.slides[this.activeIndex];
			const month = activeSlide.dataset.month;
			document.getElementById("currentMonth").innerHTML = month;
		},
		slideChange: function () {
			const activeSlide = this.slides[this.activeIndex];
			const month = activeSlide.dataset.month;
			document.getElementById("currentMonth").innerHTML = month;
		},
	},
});

